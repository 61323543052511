var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0 animation",staticStyle:{"animation-delay":"2s"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},_vm._l((_vm.awards),function(award,aw){return _c('v-row',{key:aw,class:[
          {
            'yellow-element': award.color_award == '#FFF171',
          },
          aw >= 2 ? 'animation-lazy' : 'animation'
        ],attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"awards-height",style:('background-color: ' + award.color_award),attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"awards-div-max-width"},[_c('span',{staticClass:"d-block d-md-none titles-card-projects",class:{
                    'white--text change-border-color':
                      award.color_award == '#223151' ||
                      award.color_award == '#1F6D5E',
                    'black--text':
                      award.color_award != '#1F6D5E' ||
                      award.color_award != '#223151',
                  }},[_vm._v(_vm._s(_vm.formatDate(award.card_award.dateAward)))]),_c('span',{staticClass:"d-block d-md-none titles mt-3 mt-xl-8 line-height-18",class:{
                    'white--text':
                      award.color_award == '#223151' ||
                      award.color_award == '#1F6D5E',
                  }},[_vm._v(_vm._s(award.card_award.title))]),_c('hr',{staticClass:"d-block d-md-none my-5 hr-awards",class:{
                    'change-line-to-white':
                      award.color_award == '#223151' ||
                      award.color_award == '#1F6D5E',
                  }}),_c('span',{staticClass:"titles rewrite-font-size",class:{
                    'white--text':
                      award.color_award == '#223151' ||
                      award.color_award == '#1F6D5E',
                  }},[_vm._v(" "+_vm._s(award.description)+" ")]),_c('div',{staticClass:"d-md-none mt-5"},[_c('a',{staticClass:"not-text-decoration",attrs:{"target":"_blank","href":award.card_award.link}},[_c('span',{staticClass:"mt-5 texts-news--span in-new-post aeonik-regular titles-card-projects",class:{
                        'white--text change-border-color':
                          award.color_award == '#223151' ||
                          award.color_award == '#1F6D5E',
                        'black--text':
                          award.color_award != '#1F6D5E' ||
                          award.color_award != '#223151',
                      }},[_vm._v(_vm._s(award.card_award.link_text))])])])])])],1)],1),_c('v-col',{staticClass:"awards-height",attrs:{"cols":"12","sm":"6","md":"4"}},[(award.cover != null)?[_c('v-img',{staticClass:"awards-height",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' + award.cover.path}})]:_vm._e()],2),_c('v-col',{staticClass:"awards-height d-none d-md-block",class:{
            'background-yellow-rgba': award.color_award == '#FFF171',
            'background-cream-rgba': award.color_award == '#FFF0D9',
            'background-blue-rgba': award.color_award == '#223151',
            'background-green-rgba': award.color_award == '#1F6D5E',
            'background-light-green-rgba': award.color_award == '#6CD3BF',
            'background-gray-rgba': award.color_award == '#B2B2B2',
          },attrs:{"cols":"12","md":"4"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify-sm":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"div-text-cover-max-width-awards"},[_c('span',{staticClass:"d-block titles-card-projects",class:{
                    'white--text': award.color_award == '#223151',
                  }},[_vm._v(_vm._s(_vm.formatDate(award.card_award.dateAward)))]),_c('span',{staticClass:"d-block titles my-3 my-xl-8 line-height-18 rewrite-font-size",class:{
                    'white--text':
                      award.color_award == '#223151' ||
                      award.color_award == '#1F6D5E',
                  }},[_vm._v(_vm._s(award.card_award.title))]),_c('a',{staticClass:"not-text-decoration",attrs:{"target":"_blank","href":award.card_award.link}},[_c('span',{staticClass:"mt-5 texts-news--span in-new-post aeonik-regular titles-card-projects",class:{
                      'white--text change-border-color':
                        award.color_award == '#223151' ||
                        award.color_award == '#1F6D5E',
                      'black--text':
                        award.color_award != '#1F6D5E' ||
                        award.color_award != '#223151',
                    }},[_vm._v(_vm._s(award.card_award.link_text))])])])])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }